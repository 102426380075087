#n-progress-wrapper {
  box-shadow: 0 0 5px rgb(0 0 0 / 20%) inset;
  inline-size: 100%;
  position: fixed;
  z-index: 100000000;

  .bar {
    background: black;
    block-size: 3px;
  }
}
